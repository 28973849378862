import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {

const darkMode = () => {
if (document.body.classList.contains("dark")) {
document.body.classList.remove("dark");
} else {
document.body.classList.add("dark");
}
};

return (
<>
  <footer>
    <div className="container">
      <div className="row justify-content-between">
        <div className="col-lg-6 col-md-5 mb-md-5 mb-4">
          <div><a href="javascript:void(0)"><img src="assets/footer-logo.svg"
                height={36} alt="" /></a></div>
          <p className="mt-4 mb-0">NovaDEX aspires to be the go-to DeFi project on Solana, serving 
          as a comprehensive utility to fulfill all users' DeFi needs. Its product suite includes a 
          Standard AMM, Concentrated Liquidity AMM, Spot Trading, Yield Farming and the unique advantage 
          of Incentivized Trading Based on NovaPoints.</p>
        </div>
        <div className="col-lg-6 col-md-7">
          <div className="row justify-content-md-center">
            <div className="col-md-4 col-4 mb-md-5 mb-4">
              <h5>Social</h5>
              <a href="https://twitter.com/NovaDEX_io" target={"_blank"} rel="noreferrer" className="footer-link"> Twitter</a>
              <a href="https://medium.com/@NovaDEX.io" target={"_blank"} rel="noreferrer" className="footer-link"> Medium</a>
              <a href="https://coinmarketcap.com/currencies/novadex/" target={"_blank"} rel="noreferrer" className="footer-link"> CoinMarketCap</a>
              <a href="https://www.coingecko.com/en/coins/novadex" target={"_blank"} rel="noreferrer" className="footer-link"> Coingecko</a>
              <a href="https://discord.gg/novadex" target={"_blank"} rel="noreferrer" className="footer-link"> Discord</a>
              <a href="https://docs.novadex.io/" target={"_blank"} rel="noreferrer" className="footer-link"> Docs</a>
              <a href="https://github.com/NovaDexDev/novadex-clmm" target={"_blank"} rel="noreferrer" className="footer-link"> GitHub</a>
            </div>
            <div className="col-md-3 col-4 mb-md-5 mb-4">
              <h5>Navigation</h5>
              <a href="#features" className="footer-link"> Features</a>
              <a href="#testimonial" className="footer-link"> Testimonials</a>
              <a href="#liquidity" className="footer-link"> Concentrated
                Liquidity</a>
              <a href="#benefits" className="footer-link"> Benefits</a>
              <a href="#roadmap" className="footer-link"> Roadmap</a>
              <a href="#buy" className="footer-link"> How to Buy</a>


            </div>
            <div className="col-md-5 col-4 mb-md-5 mb-4">
              <h5>Contacts</h5>
              <a href="javascript:void(0)" target={"_blank"} rel="noreferrer" className="footer-link"> Contact us</a>
              <a href="https://discord.gg/novadex" target={"_blank"} rel="noreferrer" className="footer-link">
                Open Ticket</a>
              <a href="https://docs.novadex.io/" target={"_blank"} rel="noreferrer" className="footer-link"> Brand Kit</a>

            </div>
          </div>
        </div>
      </div>
    </div>


    <div className="subfooter">
      <div className="container bt-1">
        <div className="row align-items-center">
          <div className="col-md-6">
            <a href="https://docs.novadex.io/protocol/privacy-policy" target={"_blank"} rel="noreferrer"
              className="footer-link d-inline-block mb-0">Privacy policy</a>
            <a href="https://docs.novadex.io/protocol/legal-disclaimer" target={"_blank"} rel="noreferrer"
              className="footer-link d-inline-block ps-lg-5 ps-3 mb-0">Terms of service </a>

          </div>
          <div className="col-md-6 text-md-end">
            <p>Copyright 2024 NovaDEX. All rights reserved.</p>
          </div>
        </div>

      </div>
    </div>
  </footer>




</>
);
};

export default Footer;