import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";

const Header = () => {
const [stickyClass, setStickyClass] = useState("relative");
const homeNav = () => {
if (document.body.classList.contains("home-sm")) {
document.body.classList.remove("home-sm");
} else {
document.body.classList.add("home-sm");
}
};

const stickNavbar = () => {
if (window !== undefined) {
let windowHeight = window.scrollY;
windowHeight > 50
? setStickyClass("bg-pink")
: setStickyClass("");
}
};

useEffect(() => {
window.addEventListener("scroll", stickNavbar);

return () => {
window.removeEventListener("scroll", stickNavbar);
};
}, []);

return (
<div>
  <nav className={`navbar navbar-expand-lg transition-all py-0 ${stickyClass}`}>
    <div className="container nav-bg">
      <Link to="/" className="navbar-brand">
      <img src="assets/favicon.svg" className="d-md-none d-none-nline-block" alt="" />
      <span className="d-md-inline-block d-none"><img src="assets/footer-logo.svg" height={36} alt="" /></span>
      </Link>
      <div className="d-lg-none d-inline-flex gap-2 align-items-center">
        <a href="https://app.novadex.io/" target={"_blank"} rel="noreferrer" className="btn-3 d-lg-none d-inline-flex">
          <span>Launch App</span>
        </a>
        <button className="navbar-toggler" type="button" onClick={()=> {homeNav();}}>
          <span className="iconify" data-icon="mi:menu"></span>
        </button>
      </div>

      <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
        <div className="d-lg-none d-flex justify-content-between text-end p-4">
          <Link to="/" className="navbar-brand">
          <img src="assets/logo.svg" alt="" />
          </Link>
          <a href="javascript:void(0)" onClick={()=> {homeNav();}} className="cross-btn">
            <span className="iconify" data-icon="akar-icons:cross"></span>
          </a>
        </div>
        <ul className="navbar-nav align-items-center mx-auto px-4 pe-lg-5">
          <li className="nav-item"><a href="#home" rel="noreferrer" className="nav-link" onClick={()=> {
              homeNav();}}>Home</a></li>
          <li className="nav-item"><a href="#features" rel="noreferrer" className="nav-link" onClick={()=> {
              homeNav();}}>Features</a></li>
          <li className="nav-item"><a href="#benefits" rel="noreferrer" className="nav-link" onClick={()=> {
              homeNav();}}>Benefits</a></li>
          <li className="nav-item"><a href="#buy" rel="noreferrer" className="nav-link" onClick={()=> { homeNav();}}>How
              to buy</a></li>
        </ul>
        <div className="d-flex pt-lg-0 pt-5 px-4 px-md-0">
          <div className="d-flex gap-3 align-items-center pe-4 pe-lg-5">
            <a href="https://github.com/NovaDexDev/novadex-clmm" target="_blank" rel="noreferrer" className="social-ic px-lg-1"><img src="assets/github.svg"
                alt="" /></a>
            <a href="https://medium.com/@NovaDEX.io" target="_blank" rel="noreferrer" className="social-ic px-lg-1"><img src="assets/medium.svg"
                alt="" /></a>
            <a href="https://twitter.com/NovaDEX_io" target="_blank" rel="noreferrer" className="social-ic px-lg-1"><img src="assets/twitter.svg"
                alt="" /></a>
            <a href="https://discord.gg/novadex" target="_blank" rel="noreferrer" className="social-ic px-lg-1"><img src="assets/discord.svg"
                alt="" /></a>
            <a href="https://zealy.io/c/novadex/questboard" target="_blank" rel="noreferrer" className="social-ic px-lg-1"><img src="assets/zelay.svg"
                alt="" /></a>
          </div>
          <a href="https://app.novadex.io/" target={"_blank"} rel="noreferrer" className="btn-3 d-lg-inline-flex d-none">
            <span>Launch App</span>
          </a>
        </div>
      </div>
    </div>
  </nav>
</div>
);
};

export default Header;